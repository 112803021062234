import React from 'react';
import {PaymentElement, useCheckout} from '@stripe/react-stripe-js';
import { Form, Button, Alert } from "antd";
import { useTranslation } from 'react-i18next';

const CheckoutForm = ({ total }) => {
    const {t} = useTranslation();
    const {confirm} = useCheckout();
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);

  const handleSubmit = () => {
    setLoading(true);
    confirm({
        email: "billing@smartpay.tj"
      }).then((result) => {
      if (result.type === 'error') {
        setError(result.error)
      }
      setLoading(false);
    })
  };

  return (
    <Form id="payment-form" onFinish={handleSubmit}>
      <Form.Item>
        <PaymentElement options={{layout: 'accordion'}}/>
      </Form.Item>
      <Form.Item>
        <Button 
            type="primary"
            htmlType="submit"
            loading={loading}   // АнтД сам покажет спиннер, если loading=true
            size="large"
            className='my-4 py-[10px] px-[40px] bg-indigo-700 rounded-full'
        >
            <span id="button-text" className='text-white font-bold'>
            {loading ? <div className="spinner" id="spinner"></div> : <div>{`${t('PAY')} ${total} TJS`}</div>}
            </span>
        </Button>
      </Form.Item>
      {/* Show any error or success messages */}
      {error && <div id="payment-message">{error.message}</div>}
    </Form>
  )
};

export default CheckoutForm;