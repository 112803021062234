import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { numberWithSpaces, withRouter, API_KEY } from "../GlobalFunctions";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin, Collapse, Form, Input, message } from "antd";
import { Link } from "react-router-dom";
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";
import AuthCode from "react-auth-code-input";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function ServiceForm(props) {
  const { params, translation } = props; // withRouter передаёт params
  const formRef = useRef(null);

  // Состояния
  const [isLoaded, setIsLoaded] = useState(false);
  const [requestFailed, setRequestFailed] = useState(false);
  const [data, setData] = useState(null);
  const [qty, setQty] = useState(1);
  const [price, setPrice] = useState(1);
  const [phone, setPhone] = useState("");
  const [token, setToken] = useState("");
  const [isPhoneEntered, setIsPhoneEntered] = useState(false);

  // Загрузка данных при монтировании
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setTokenFunc = (getToken) => {
    setToken(getToken);
  };

  const onFinish = () => {
    fetch(`https://smartpay.tj/api/v1/invoices/sue/new`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json",
        "x-app-token": API_KEY(),
      },
      body: JSON.stringify({
        service_id: parseInt(params.serviceId),
        cat_id: parseInt(params.catId),
        phone: phone,
        qty: qty,
        ticket: false,
        price: price,
        seans: "",
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          setRequestFailed(true);
          message.error("Дархост қайд нашуд");
        }
      })
      .then((responseJson) => {
        window.location.href = `https://smartpay.tj/invoice/${responseJson.invoice_id}`;
      });
  };

  const getData = () => {
    fetch(
      `https://smartpay.tj/api/v1/services/sue?merchantId=${params.merchantId}&catId=${params.catId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Accept: "application/json",
          "x-app-token": API_KEY(),
        },
      }
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          setRequestFailed(true);
        }
      })
      .then((responseJson) => {
        setData(responseJson);
        setIsLoaded(true);
      });
  };

  // Находим выбранный сервис по params.serviceId
  const selectedService = useMemo(() => {
    if (data && data.category) {
      for (let item of data.category) {
        const found = item.services.find(
          (service) => service.id === parseInt(params.serviceId)
        );
        if (found) return found;
      }
    }
    return null;
  }, [data, params.serviceId]);

  // Если выбранный сервис найден и у него есть цена (>0), обновляем локальное состояние и поле формы
  useEffect(() => {
    if (selectedService && selectedService.price > 0) {
      // Обновляем поле "price" формы через setFieldsValue
      formRef.current?.setFieldsValue({ price: selectedService.price });
      setPrice(selectedService.price);
    }
  }, [selectedService]);

  // Мемоизируем компонент reCAPTCHA, чтобы он монтировался один раз
  const recaptchaComponent = useMemo(
    () => (
      <GoogleReCaptchaProvider reCaptchaKey="6LdbjS0qAAAAAFrFSN5HHvRkffPfeG-078Hbadb_">
        <GoogleReCaptcha
          className="google-recaptcha-custom-class"
          onVerify={setTokenFunc}
          refreshReCaptcha={false}
        />
      </GoogleReCaptchaProvider>
    ),
    []
  );

  const { Panel } = Collapse;

  if (!isLoaded) {
    return (
      <div className="flex w-full h-[80vh] justify-center items-center">
        <Spin size="large" indicator={antIcon} />
      </div>
    );
  }

  return (
    <div
      id="InvoiceContainer"
      style={{ textAlign: "-webkit-center" }}
      className="block bg-white text-center"
    >
      <>
        <div className="flex md:w-[55%] mt-4">
          <div className="flex w-[25%] ml-4 justify-center items-center">
            <img alt="" className="sm:!h-[86px]" src={data.logo} />
          </div>
          <div className="w-[75%] block text-center">
            <div className="mx-2 sm:text-[14px] md:text-[18px] upper font-semibold">
              {data.name}
            </div>
            <div className="mx-2 sm:text-[11px] md:text-[13px] text-center py-[5px]">
              {data.address}{" "}
              {`${translation("TEL")} ${data.phone} ${translation("EMAIL")} ${data.email}`}
            </div>
          </div>
        </div>

        {params.serviceId ? (
          <>
            {data.category.map((item, idx) =>
              item.services.map(
                (service, sidx) =>
                  service.id === parseInt(params.serviceId) && (
                    <Fragment key={sidx}>
                      <Form id="appform" className="" ref={formRef} onFinish={onFinish}>
                        <div className="mx-4 md:w-1/2">
                          <div className="flex mt-4">
                            <div className="w-[30%] text-left">Хизматрасонӣ</div>
                            <div className="w-[70%] text-right">{service.name}</div>
                          </div>
                          <div className="flex mt-4">
                            <div className="w-[30%] text-left">Нарх</div>
                            <Form.Item
                              name="price"
                              className="flex justify-center w-full m-0"
                            >
                              {/* Если у сервиса есть цена, значение устанавливается через setFieldsValue */}
                              {service?.price ? (
                                <div className="w-[70%] text-right">
                                  {price.toFixed(2)}
                                </div>
                              ) : (
                                <Input
                                  onChange={(e) => {
                                    setPrice(e.target.value);
                                  }}
                                  defaultValue={1}
                                  className="text-center bg-white border px-2 py-1 rounded-[4px] mx-auto"
                                  type="number"
                                  name="price"
                                  required
                                />
                              )}
                            </Form.Item>
                          </div>
                          <div className="flex mt-4">
                            <div className="w-[30%] text-left">Адад</div>
                            <div className="w-[70%] text-right">
                              <Form.Item name="qty" className="m-0">
                                <div className="flex w-2/3 justify-between items-center">
                                  <div
                                    onClick={() => {
                                      if (qty > 2) {
                                        setQty((prev) => prev - 1);
                                      }
                                    }}
                                    className="w-[38px] h-[40px] bg-blue-500 active:bg-[#212462] rounded-[7px] cursor-pointer align-middle text-center text-[#ffffff] text-[24px] font-[500]"
                                  >
                                    -
                                  </div>
                                  <p className="font-[500] text-[22px] text-black">{qty}</p>
                                  <div
                                    onClick={() => setQty((prev) => prev + 1)}
                                    className="w-[38px] h-[40px] bg-blue-500 active:bg-[#212462] rounded-[7px] cursor-pointer align-middle text-center text-[#ffffff] text-[24px] font-[500]"
                                  >
                                    +
                                  </div>
                                </div>
                              </Form.Item>
                            </div>
                          </div>
                          <div className="flex mt-4">
                            <div className="w-[30%] text-left font-semibold">Ҳамагӣ</div>
                            <div className="w-[70%] text-right font-semibold">
                              {numberWithSpaces((qty * price).toFixed(2))}
                            </div>
                          </div>

                          <div className="mt-4">
                            <div className="text-center font-semibold">
                              Рақами телефон барои гирифтани смс паёмак
                            </div>
                            <Form.Item name="phone" className="m-0" required>
                              <AuthCode
                                inputClassName="sm:w-[25px] sm:h-[40px] sm:text-[16px] sm:rounded-[5px] sm:mx-[1.5px] md:w-[35px] md:h-[45px] md:text-[24px] text-center md:mx-1 md:rounded-[8px] border-solid border-[1px] border-[#d6d6d6]"
                                onChange={(value) => {
                                  setPhone(value);
                                  if (value.length === 9) {
                                    setIsPhoneEntered(true);
                                  } else {
                                    setIsPhoneEntered(false);
                                  }
                                }}
                                name="phone"
                                length={9}
                                allowedCharacters="numeric"
                              />
                            </Form.Item>
                          </div>
                          <div className="mt-4">
                            <button
                              className={`${
                                isPhoneEntered
                                  ? "visible sm:w-[70%] md:m-8 my-2 px-4 py-2 bg-indigo-500 text-white rounded-md md:w-[200px] hover:bg-indigo-600"
                                  : "hidden"
                              }`}
                              type="submit"
                              form="appform"
                            >
                              {translation("PAY")}
                            </button>
                          </div>
                        </div>
                        {recaptchaComponent}
                      </Form>
                    </Fragment>
                  )
              )
            )}
          </>
        ) : (
          <>
            {data.category.map((item, index) => (
              <Collapse accordion className="mx-4 my-4 md:w-1/2" key={index}>
                <Collapse.Panel header={item.name} key={index}>
                  {item.services.map((service, ind) => (
                    <Link to={`${service.id}`} key={ind}>
                      <div className="flex m-4 rounded-[10px] bg-indigo-600">
                        <div className="p-2 text-white text-center">
                          {`${ind + 1}. ${service.name} - ${numberWithSpaces(
                            service.price.toFixed(2)
                          )} смн.`}
                        </div>
                      </div>
                    </Link>
                  ))}
                </Collapse.Panel>
              </Collapse>
            ))}
          </>
        )}
      </>
    </div>
  );
}

export default withRouter(ServiceForm);